/* src/pages/Trade.css */

/* Trade container */
.trade {
  background-color: var(--major-backgroundColor);
  color: var(--minor-color);
  padding: 2rem 0;
  text-align: center;
  flex: 1;
}

/* Trade title */
.trade h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* src/pages/Trade.css */

/* Trade content container using CSS Flexbox */
.trade-content {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center; /* Center the content horizontally */
}

.order-content {
  margin-right: 1rem;
  display: flex;
  flex-direction: column; /* Stack components vertically */
  align-items: center; /* Center align components horizontally */
  width: 100%; /* Ensure both components share the same width */
  max-width: 1000px; /* Max width for the container */
  gap:10px;
}

/* Style individual components within trade-content as needed */

/* Style for OrderBook */
.order-book {
  /* Your existing styles for OrderBook */
}

/* Style for OrderForm */
.order-form {
  /* Your existing styles for OrderForm */
}
